/* Component Dependencies */
var sessionTimeOutTemplate = require('templates/sessionTimeOut.hbs');
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');

AriesComponent.create({
  type: 'sessionTimeOut',
  template: {
    'sessionTimeOut': sessionTimeOutTemplate
  },
  bindEvents: function() {
    var _self = this;
    if (window.localStorage) {
      localStorage.setItem('tab_dirty_session', true);
    }
    var expiredIn = _self.$el.find('input[name="expiredIn"]').val();
    $(document).on('click keydown touchstart contextmenu', function() {
      _self.initializeSessionTimer();
      if (window.localStorage) {
        localStorage.setItem('tab_dirty_session', true);
      }
    });
    window.addEventListener('storage', function(storageEvent) {
      if (storageEvent.key === 'tab_dirty_session' && storageEvent.newValue === 'true') {
        _self.initializeSessionTimer();
      }
    });
    _self.initializeSessionTimer();
  },
  /**
   * This method bind events on click,touch and key and initiate the timer
   * @return {boolean} operation success or not
   */
  initializeSessionTimer: function _initializeSessionTimer() {
    var _self = this;
    if (_self.timer !== 'undefined') {
      clearTimeout(_self.timer);
    }
    _self.timer = setTimeout(function() {
      _self.timeoutRedirect();
    }, _self.$el.find('input[name="expiredIn"]').val());
    localStorage.setItem('tab_dirty_session', false);
    return true;
  },
  /**
   * This method initiates the form submit on timer completeion
   * @return {boolean} operation success or not
   */
  timeoutRedirect: function _timeoutRedirect() {
    var _self = this;
    var dataObj = { "returnTo" : window.location.href }
    _self.makeAjaxCall({
      method: 'POST',
      url: _self.$el.find('input[name="submitAction"]').val(),
      data: JSON.stringify(dataObj),
      success: function(data) {
        if (data.redirect === true) {
          window.location.href = data.nextStateURI;
        }
      }
    });
    return true;
  }

});